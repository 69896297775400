import React, { FC } from 'react'

export const Logo: FC = () => (
<svg version="1.1" id="LOGO_RGB" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="110" height="24" viewBox="0 0 500 110.5">
  <g>
    <path fill="#052439" d="M56,69.8V0.2h22.7v66.7c0,4.7,1,5.8,5.8,5.8h1.9l2.7,18.4c-1.7,0.2-8.7,0.7-13,0.7C63.7,91.9,56,87.5,56,69.8z
      "/>
    <path fill="#052439" d="M229.8,27.3l17.6-1.4l1.7,5.3c3.5-3.3,9.7-6.6,23-6.6c22.9,0,28.2,16.1,28.2,34.1c0,18.1-5.8,34-29.6,34
      c-10.5,0-15.5-2.4-18.3-5v22.9h-22.7V27.3z M277.3,59.6c0-7.7-1.4-14.4-11.2-14.4c-10.5,0-13.6,5.4-13.6,14.4s2.3,14,13.6,14
      C275.7,73.5,277.3,67.2,277.3,59.6z"/>
    <path fill="#052439" d="M381.2,27.3l17.6-1.4l2.3,6.9c2.9-3.9,10.8-7.2,20.2-7.6V47c-15.1,0.1-17.4,4.6-17.4,13.6v31h-22.7V27.3z"/>
    <path fill="#052439" d="M427.6,25.2h11.7l3.7-13.3l17.5-3.8v17.1h14.3v21.8h-14.3V66c0,4.5,0.7,6.8,7.8,6.8h6.5v18.9
      c-3.4,0.2-7.2,0.3-11.5,0.3c-19.1,0-25.4-6.1-25.4-22.6V47.1h-10.3V25.2z"/>
    <rect x="481.1" y="72.7" fill="#FA5959" width="18.9" height="18.9"/>
    <path fill="#052439" d="M0,26h12.4v-1.3C12.4,8.2,18.6,0,39.9,0c5.6,0,6.6,0.1,9.4,0.3v18.4H43c-6.5,0-7.9,1.6-7.9,5.8V26h14.2v19.2
      H35v46.4H12.4V45.2H0V26z"/>
    <path fill="#052439" d="M138.5,72c-3.8,2.2-5.7,2.7-13.2,2.7c-8.9,0-12.3-3.2-13.4-8.5h43.6c0.8-23.1-1.1-41.8-30.2-41.8
      c-29.1,0-35.3,16.1-35.3,33.9c0,18.3,6.1,34.5,35.3,34.5c11.5,0,18.7-2.1,26.4-7.1L138.5,72z M124.7,43.1c9.3,0,10,4.4,10.4,9.8
      h-23.3C112.6,46.6,115.6,43.1,124.7,43.1z"/>
    <g>
      <polygon fill="#052439" points="200.5,91.7 189.4,75.8 178.3,91.7 152.9,91.7 176.7,57.8 155.1,27.3 180.7,27.3 189.5,39.7 
        198.3,27.3 224.2,27.3 202.2,57.7 226,91.7 		"/>
    </g>
    <path fill="#052439" d="M305,58.6c0-21.8,10-34.1,35.6-34.1c25.7,0,35.5,12.4,35.5,34.1c0,21.8-9.9,34.2-35.5,34.2
      C315,92.9,305,80.4,305,58.6z M353.3,59.3c0-8.2-2.1-14.2-12.7-14.2c-10.7,0-12.7,6-12.7,14.2c0,8.1,2.1,14.2,12.7,14.2
      C351.3,73.5,353.3,67.5,353.3,59.3z"/>
  </g>
</svg>
)
