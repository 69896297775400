/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'

import { Header } from './header'
import './layout.css'
import './layout-custom.css'
import {
  bottomContainer,
  leftContainer,
  rightContainer,
  topContainer,
} from './style'
import { black } from './colors'
import SEO from './seo'
import { Nav } from './nav/nav'

export type LayoutProps = {
  pageContext?: Partial<{
    frontmatter: Partial<{
      title: string
      description: string
    }>
  }>
}

const Layout: FC<LayoutProps> = ({ children, ...props }) => {
  const frontmatter = props.pageContext?.frontmatter

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = frontmatter?.title ?? ''
  const description =
    frontmatter?.description ?? data.site.siteMetadata?.title ?? ''

  return (
    <div
      css={css`
        min-height: 100%;
        font-family: Lato;
        color: ${black};
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: 280px auto;
        grid-template-areas:
          'nav main'
          'nav footer';
      `}
    >
      <SEO title={title} description={description} />
      <Nav
        css={[
          css`
            grid-area: nav;
            position: fixed;
          `,
          topContainer,
          bottomContainer,
          leftContainer,
        ]}
      />
      <main
        css={[
          css`
            max-width: 50rem;
            grid-area: main;
            border-left: 1px solid #ddd;
          `,
          topContainer,
          bottomContainer,
          rightContainer,
        ]}
      >
        <h1
          css={css`
            font-size: 36px;
            margin-bottom: 0;
          `}
        >
          Website Fast Tags
        </h1>
        {children}
      </main>
      <footer
        css={[
          css`
            padding-top: 1.45rem;
            grid-area: footer;
            border-left: 1px solid #ddd;
          `,
          bottomContainer,
          rightContainer,
        ]}
      >
        © {new Date().getFullYear()} Flexport, Inc.
      </footer>
    </div>
  )
}

export default Layout
