import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { WithStyleProps } from '../util/with-style-props'

export type NavItemProps = {
  path: string
  childItems?: ReactNode
} & WithStyleProps

export const NavItem: FC<NavItemProps> = ({
  path,
  children,
  childItems = null,
  className,
}) => (
  <div
    css={css`
      margin: 0.2rem 0;
    `}
    className={className}
  >
    <a href={path}>{children}</a>
    <div
      css={css`
        padding-left: 0.7em;
      `}
    >
      {childItems}
    </div>
  </div>
)
