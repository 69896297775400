import React, { FC, Fragment, ReactNode } from 'react'
import { css } from '@emotion/react'
import { WithStyleProps } from '../util/with-style-props'
import { NavItem } from './nav-item'
import { Header } from '../header'
import { black, darkGray, navy } from '../colors'

export type NavProps = WithStyleProps

export const Nav: FC<NavProps> = ({ className }) => (
  <nav
    className={className}
    css={css`
      & a {
        color: ${navy};

        &:hover {
          color: ${darkGray};
        }
      }
    `}
  >
    <Header />
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <NavItem path="/">Home</NavItem>
      <NavItem
        path="/guide"
        childItems={
          <Fragment>
            <NavItem path="/guide/#including-the-library">
              Including the library
            </NavItem>
            <NavItem path="/guide/#adding-the-badge-component">
              Adding the badge component
            </NavItem>
            <NavItem
              path="/guide/#connecting-product-info"
              childItems={
                <Fragment>
                  <NavItem path="/guide/#connecting-product-info-using-data-skus">
                    Using data-skus
                  </NavItem>
                  <NavItem path="/guide/#connecting-product-info-using-data-dskus">
                    Using data-dskus
                  </NavItem>
                </Fragment>
              }
            >
              Connecting product info
            </NavItem>
            <NavItem path="/guide/#displaying-the-offer-at-checkout">
              Displaying the offer at checkout
            </NavItem>
            <NavItem path="/guide/#configuring-the-application"
              childItems={
                <Fragment>
                  <NavItem path="/guide/#configuring-cart-minimums">
                    Configuring cart minimums
                  </NavItem>
                  <NavItem path="/guide/#setting-a-cart-size-and-price">Setting a cart size and price</NavItem>
                </Fragment>
              }>
              Configuring the application
            </NavItem>
          </Fragment>
        }
      >
        Guide
      </NavItem>
      <NavItem
        path="/reference"
        childItems={
          <Fragment>
            <NavItem
              path="/reference/#web-components"
              childItems={
                <Fragment>
                  <NavItem path="/reference/#deliverr-tag">
                    deliverr-tag
                  </NavItem>
                  <NavItem path="/reference/#deliverr-tag-extended">
                    deliverr-tag-extended
                  </NavItem>
                </Fragment>
              }
            >
              Web Components
            </NavItem>
            <NavItem
              path="/reference/#deliverr-fast-tags-global"
              childItems={
                <Fragment>
                  <NavItem
                  path="/reference/#app-config"
                 >
                   appConfig: App Configuration
                  </NavItem>
                </Fragment>
              }>
                DeliverrFastTags Global
            </NavItem>
            <NavItem
              path="/reference/#api-client"
              childItems={
                <Fragment>
                  <NavItem path="/reference/#getshippingoptions">
                    getShippingOptions
                  </NavItem>
                </Fragment>
              }
            >
              API Client
            </NavItem>
          </Fragment>
        }
      >
        Reference
      </NavItem>
    </div>
  </nav>
)
