import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React, { FC } from 'react'
import { black, red } from './colors'
import { Logo } from './logo'
import { header } from './style'
import { WithStyleProps } from './util/with-style-props'

type HeaderProps = WithStyleProps

export const Header: FC<HeaderProps> = ({ className }) => (
  <header className={className}>
    <div
      css={[
        css`
          font-size: 26px;
          margin-bottom: 1.45rem;
        `,
        header,
      ]}
    >
      <Link
        to="/"
        css={css`
          color: ${red};
          text-decoration: none;
          display: flex;
          align-items: center;
          font-weight: 400;
        `}
      >
        <Logo />
        <span
          css={css`
            margin-left: 8px;
            color: ${black};
          `}
        >
          Docs
        </span>
      </Link>
    </div>
  </header>
)
